import { Tickets } from "src/core/ticket/domain/tickets";
import { TicketDetail } from "src/core/ticket/domain/ticket-detail";
import { TicketList } from "src/core/ticket/domain/ticket-list";

export abstract class TicketRepository {
  abstract getTicketsList(reservationId: string): Promise<TicketList>;

  abstract getTickets(): Promise<Tickets>;

  abstract getTicket(ticketId: string, reservationId: string): Promise<TicketDetail>;

  abstract add(ticketId: string, reservationId: string): Promise<void>;

  abstract delete(ticketId: string): Promise<void>;

  abstract invitePeople(reservationId: string, tickets: Array<{ ticketId: string; email: string }>): Promise<void>;
}
