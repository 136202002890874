import { HttpErrorResponse } from '@angular/common/http';
import { ApiException, ApiExceptionConstructor } from "src/core/shared/exceptions/api-exception";

export class EmailIsNotAllowedException extends ApiException implements ApiExceptionConstructor {
  constructor(message: string = '', error: HttpErrorResponse | Error) {
    super(message, error);
    this.name = 'EmailIsNotAllowedException';
  }

  static fromError(error: HttpErrorResponse | Error): EmailIsNotAllowedException {
    return new this(error.message, error);
  }
}
