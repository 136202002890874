import { Injectable } from '@angular/core';
import { ExperienceRepository } from "../domain/experience.repository";
import { Experience } from "../domain/experience";
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders, HttpRequest, HttpResponse } from "@angular/common/http";
import { ApiGetExperiencesResponse } from "./api-get-experiences-response";
import { last, lastValueFrom, tap } from "rxjs";
import { SessionService } from "../../session/domain/session.service";
import { Experiences } from "src/core/experiences/domain/experiences";
import { ExperienceStep } from "src/core/experiences/domain/experience-step";
import { LocalDate } from "src/core/shared/date/local-date";
import { ApiService } from "src/core/api/domain/api.service";
import { ApiAccessService } from "src/core/api/domain/api-access.service";
import { ExceptionManagerService } from "src/core/shared/exceptions/exception-manager.service";
import {
  ApiGetVisitorExperiencesResponse,
} from "src/core/experiences/infrastructure/api-get-visitor-experiences-response";
import { filter } from "rxjs/operators";

@Injectable({
  providedIn: 'any',
})
export class HttpExperienceRepository extends ExperienceRepository {
  constructor(
    private readonly apiService: ApiService,
    private readonly apiAccessService: ApiAccessService,
    private readonly http: HttpClient,
    private readonly sessionService: SessionService,
    private readonly exceptionManager: ExceptionManagerService,
  ) {
    super();
  }

  async load(): Promise<Experiences> {
    return new Promise(async (resolve, reject) => {
      try {
        const language = this.sessionService.currentLanguage();
        const allExperiencesResponse = await this.loadAllExperiences(language);
        const visitorExperiencesResponse = await this.loadVisitorExperiences(language);
        resolve(new Experiences(this.parseApiLoadResponse(allExperiencesResponse, visitorExperiencesResponse)));
      } catch (exception) {
        reject(this.exceptionManager.manage(exception));
      }
    });
  }

  async loadImage(url: string): Promise<Blob> {
    const options = {
      responseType: 'blob' as 'json',
      reportProgress: true,
      observe: 'events',
    };
    const req = new HttpRequest('GET', url, options);
    const response: HttpResponse<Blob> = await lastValueFrom(
      this.http.request<Blob>(req).pipe(
        tap((event: HttpEvent<Blob>) => {
          switch (event.type) {
            case HttpEventType.Sent:
              break;
            case HttpEventType.DownloadProgress:
              break;
            case HttpEventType.Response:
              break;
          }
        }),
        filter((event: HttpEvent<Blob>): event is HttpResponse<Blob> => event instanceof HttpResponse),
        last(),
      ),
    );

    if (response.type !== HttpEventType.Response) {
      throw new Error('Unexpected response type');
    }

    return response.body as Blob;
  }


  private async loadResponseMocked(language: string): Promise<ApiGetExperiencesResponse> {
    return await lastValueFrom(this.http.get('/assets/api-mocked/get-experiences.response.json')) as ApiGetExperiencesResponse;
  }

  private async loadVisitorExperiences(language: string): Promise<ApiGetVisitorExperiencesResponse> {
    await this.apiAccessService.renewApiAccessIfNeeded();

    const baseUrl = this.apiService.currentApi().urlVisitor;
    const url = `${baseUrl}/visitors/experiences`;
    const apiToken = this.sessionService.getApiToken();
    const appVersion = '1.0'; // set to 1.0.0 when fixed in the API
    const apiVersion = '1.0';
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('X-LANGUAGE', language);
    httpHeaders = httpHeaders.set('X-APP-VERSION', appVersion);
    httpHeaders = httpHeaders.set('X-API-VERSION', apiVersion);
    httpHeaders = httpHeaders.set('Authorization', `Bearer ${apiToken?.token ?? '--'}`);
    const options = { headers: httpHeaders };

    return await lastValueFrom(this.http.get<ApiGetVisitorExperiencesResponse>(url, options));
  }


  private async loadAllExperiences(language: string): Promise<ApiGetExperiencesResponse> {
    await this.apiAccessService.renewApiAccessIfNeeded();

    const baseUrl = this.apiService.currentApi().urlMobile;
    const apikey = this.apiService.currentApi().apiKeyMobile;
    const url = `${baseUrl}/experiences`;
    const apiToken = this.sessionService.getApiToken();
    const appVersion = '1.0'; // set to 1.0.0 when fixed in the API
    const apiVersion = '1.0';
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('X-API-KEY', apikey);
    httpHeaders = httpHeaders.set('X-LANGUAGE', language);
    httpHeaders = httpHeaders.set('X-APP-VERSION', appVersion);
    httpHeaders = httpHeaders.set('X-API-VERSION', apiVersion);
    httpHeaders = httpHeaders.set('Authorization', `Bearer ${apiToken?.token ?? '--'}`);
    const options = { headers: httpHeaders };

    return await lastValueFrom(this.http.get<ApiGetExperiencesResponse>(url, options));
  }

  private parseApiLoadResponse(experiencesResponse: ApiGetExperiencesResponse, visitorExperiencesResponse: ApiGetVisitorExperiencesResponse): Experience[] {

    const responseVisitorExperiences =
      'experiences' in visitorExperiencesResponse
        ? visitorExperiencesResponse.experiences // Scenario with an object with `experiences`
        : visitorExperiencesResponse; // Scenario where directly an array

    const idsToShow = responseVisitorExperiences.map(e => e.id);

    return experiencesResponse.experiences
      // #RS_BUILD_SETTING Enable next line when DIST build. Comment it to show all experiences, not only the ones the user has access to.
      .filter(experienceData => idsToShow.indexOf(experienceData.id) !== -1)
      .map(experienceData => {
        const steps = experienceData.steps.map(step => {
          return ExperienceStep.fromPrimitives(step);
        }).sort((a, b) => a.order - b.order);

        // hack to always be updated, in order to force deleting
        // experienceData.updatedAt = moment().format();

        const imageUrl = experienceData.imageUrl ?? null;
        console.log(':::imageURL: ', imageUrl);

        return new Experience(
          experienceData.id,
          experienceData.name,
          experienceData.availableLanguages,
          steps,
          LocalDate.fromIsoString(experienceData.updatedAt),
          imageUrl,
        );
      });
  }
}
