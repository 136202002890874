import { Routes } from '@angular/router';
import { checkAuthorizationCodeInUrlGuard } from "src/app/guards/check-authorization-code-in-url.guard";
import { redirectIfLoggedGuard } from "src/app/guards/redirect-if-logged.guard";
import { authGuard } from "src/app/guards/auth.guard";

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.routes').then((m) => m.routes),
    canActivate: [ checkAuthorizationCodeInUrlGuard, authGuard ],
  },
  {
    path: 'welcome',
    loadComponent: () => import('./welcome/welcome.page').then((m) => m.WelcomePage),
    canActivate: [ redirectIfLoggedGuard ],
  },
  {
    path: 'register',
    loadComponent: () => import('./register/register.page').then((m) => m.RegisterPage),
    canActivate: [ redirectIfLoggedGuard ],
  },
  {
    path: 'add-tickets',
    loadComponent: () => import('./add-tickets/add-tickets.page').then((m) => m.AddTicketsPage),
  },
  {
    path: 'wizard',
    loadComponent: () => import('./wizard/wizard.page').then((m) => m.WizardPage),
  },
  {
    path: 'profile',
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./user-profile/user-profile.page').then((m) => m.UserProfilePage),
      },
      {
        path: 'edit',
        loadComponent: () =>
          import('./edit-user-profile/edit-user-profile.page').then((m) => m.EditUserProfilePage),
      },
    ],
    canActivate: [ authGuard ],
  },
  {
    path: '404',
    loadChildren: () => import('./not-found/not-found.page').then((m) => m.NotFoundPage),
  },
];
