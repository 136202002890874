import { Injectable } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular/standalone';
import { TranslatorService } from "src/core/shared/translations/domain/translator.service";
import { Nullable } from "src/core/shared/types/nullable.type";

@Injectable({
  providedIn: 'root',
})
export class MessageHelper {
  constructor(
    private readonly alertController: AlertController,
    private readonly toastCtrl: ToastController,
    private readonly translation: TranslatorService,
  ) {}

  async showSuccess(message: string, duration: number = 2000): Promise<void> {
    const toast = await this.toastCtrl.create({
      message,
      color: 'success',
      duration,
    });
    await toast.present();
  }

  async showWarning(message: string, duration: number = 2000): Promise<void> {
    const toast = await this.toastCtrl.create({
      message,
      color: 'warning',
      duration,
    });
    await toast.present();
  }

  async showInfo(message: string, duration: number = 2000): Promise<void> {
    const toast = await this.toastCtrl.create({
      message,
      color: 'dark',

      duration,
    });
    await toast.present();
  }

  async showError(message: string): Promise<void> {
    const ok = await this.translation.translate('COMMON.OK');
    const toast = await this.toastCtrl.create({
      message,
      color: 'danger',
      buttons: [
        {
          text: ok,
          role: 'cancel',
          handler: () => {},
        },
      ],
    });
    await toast.present();
  }

  async showAlertError(header: string, message: string): Promise<void> {
    const alert = await this.alertController.create({
      header,
      message,
      buttons: [ 'OK' ],
    });
    await alert.present();
  }

  async askConfirmation(header: string, message: string, okButtonText: Nullable<string> = null): Promise<boolean> {
    const ok = okButtonText ?? this.translation.instant('COMMON.OK');
    const cancel = this.translation.instant('COMMON.CANCEL');

    return new Promise(async (resolve) => {
      const alert = await this.alertController.create({
        header,
        message,
        buttons: [
          {
            text: cancel,
            role: 'cancel',
            handler: () => {
              resolve(false);
            },
          },
          {
            text: ok,
            handler: () => {
              resolve(true);
            },
          },
        ],
      });
      await alert.present();
    });
  }

  async showNeedsConnectionInfo() {
    await this.showWarning(this.translation.instant('MESSAGES.NEEDS_CONNECTION'));
  }
}
