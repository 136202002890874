import { UserInfo } from "src/core/user/domain/user-info";

export abstract class UserRepository {
  abstract getInfo(): Promise<UserInfo>;

  abstract updateUserData(firstName: string, lastName: string): Promise<void>;

  abstract delete(userId: string): Promise<void>;

  abstract create(data: { email: string; password: string; name: string; surname: string; countryCode: string; }): Promise<void>;
}
