import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { lastValueFrom } from "rxjs";
import { ApiService } from "src/core/api/domain/api.service";
import { ExceptionManagerService } from "src/core/shared/exceptions/exception-manager.service";
import { CountryRepository } from "src/core/countries/domain/country.repository";
import { ApiGetCountriesResponse } from "src/core/countries/infrastructure/api-get-countries-response";
import { Country } from "src/core/countries/domain/country";
import { Countries } from "src/core/countries/domain/countries";

@Injectable({
  providedIn: 'any',
})
export class HttpCountryRepository extends CountryRepository {
  constructor(
    private readonly apiService: ApiService,
    private readonly http: HttpClient,
    private readonly exceptionManager: ExceptionManagerService,
  ) {
    super();
  }

  async load(language: string): Promise<Countries> {
    return new Promise(async (resolve, reject) => {
      try {
        const countriesResponse = await this.loadCountries(language);
        console.log(`Countries response`);
        console.log(countriesResponse);
        resolve(new Countries(this.parseApiLoadResponse(countriesResponse)));
      } catch (exception) {
        reject(this.exceptionManager.manage(exception));
      }
    });
  }

  private async loadCountries(language: string): Promise<ApiGetCountriesResponse> {
    const baseUrl = this.apiService.currentApi().urlVisitor;
    const apikey = this.apiService.currentApi().apiKeyVisitor;
    const url = `${baseUrl}/countries/${language}`;
    console.log(url);
    const appVersion = '1.0'; // set to 1.0.0 when fixed in the API
    const apiVersion = '1.0';
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('X-API-KEY', apikey);
    httpHeaders = httpHeaders.set('X-LANGUAGE', language);
    httpHeaders = httpHeaders.set('X-APP-VERSION', appVersion);
    httpHeaders = httpHeaders.set('X-API-VERSION', apiVersion);
    const options = { headers: httpHeaders };

    return await lastValueFrom(this.http.get<ApiGetCountriesResponse>(url, options));
  }

  private parseApiLoadResponse(countriesResponse: ApiGetCountriesResponse): Array<Country> {
    return countriesResponse.countries
      .map(countryData => (new Country(countryData.code, countryData.name)));
  }
}
