import { Injectable } from "@angular/core";
import { ApiService } from "src/core/api/domain/api.service";
import { Smartlook } from "cordova-plugin-smartlook";
import { PlatformService } from "src/core/platform/platform.service";
import { PrivacyPolicyService } from "src/core/privacy-policy/domain/privacy-policy.service";

declare var Smartlook: Smartlook;

@Injectable({
  providedIn: 'root',
})
export class TrackingService {
  constructor(
    private readonly platformService: PlatformService,
    private readonly apiService: ApiService,
    private readonly privacyPolicyService: PrivacyPolicyService,
  ) {}

  init(): void {
    if (!this.platformService.isCordova()) {
      return;
    }

    if (!Smartlook) {
      console.warn('Tracking service: Smartlook not found');
      return;
    }

    if (!this.privacyPolicyService.isAnalyticsCookiesEnabled()) {
      console.log(' - analytics cookies not enabled, skipping Smartlook');
      return;
    }

    const key = this.apiService.currentApi().smartlookApiKey;
    if (key === null) {
      console.log('Tracking service: Smartlook skipped (dev)');
      return;
    }

    console.log('Tracking service: Smartlook init');
    this.setupAndStartRecording(key);
  }

  async restart() {
    if (!this.platformService.isCordova()) {
      return;
    }

    if (!Smartlook) {
      console.warn('Tracking service: Smartlook not found');
      return;
    }

    if (!this.privacyPolicyService.isAnalyticsCookiesEnabled()) {
      console.log(' - analytics cookies not enabled, skipping Smartlook');
      return;
    }

    Smartlook.stopRecording(
      (value: any) => { console.log('Tracking service: stopped recording', value); },
      (errorMessage: any) => { console.error('Tracking service: an error occurred while stop recording', errorMessage); },
    );

    const key = this.apiService.currentApi().smartlookApiKey;
    if (key === null) {
      console.log('Tracking service: Smartlook skipped (dev)');
      return;
    }

    this.setupAndStartRecording(key);
  }

  private setupAndStartRecording(key: string) {
    // alert('Usando key: ' + key);
    const options = { smartlookAPIKey: key };
    Smartlook.setupAndStartRecording(
      options,
      (value: any) => {
        console.log(':::tracking.service:setupAndStartRecording:ok');
        // alert('Tracking service: started recording ' + value);
        },
      (errorMessage: any) => {
        console.log(':::tracking.service:setupAndStartRecording:error');
        // alert('Tracking service: an error occurred while starting to record: ' + errorMessage);
        },
    );
  }
}
