import { Initializable } from "src/core/shared/initializer/domain/initializable";
import { Storable } from "src/core/shared/storage/domain/storable";
import { BehaviorSubject, Observable } from "rxjs";
import { StorageService } from "src/core/shared/storage/domain/storage.service";
import { Injectable } from "@angular/core";
import { PlatformService } from "src/core/platform/platform.service";
import { Nullable } from "src/core/shared/types/nullable.type";
import { FilesystemService } from "src/core/filesystem/domain/filesystem.service";

@Injectable({
  providedIn: 'root',
})
export class MediaService implements Storable, Initializable {
  private readonly KEY_PREFIX_MEDIA = "media";
  private readonly KEY_PREFIX_STEP_IMAGE = "image";
  private readonly KEY_PREFIX_EXPERIENCE_IMAGE = "experience_image";

  private readySubject = new BehaviorSubject<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  ready$: Observable<boolean> = this.readySubject.asObservable();

  constructor(
    private readonly storage: StorageService,
    private readonly filesystem: FilesystemService,
    private readonly platformService: PlatformService,
  ) {}

  async init() {
    this.readySubject.next(true);
  }

  async clear() {
    await this.clearAllMedias();
    await this.clearAllExperienceImages();
    await this.clearAllStepImages();
  }

  async save(experienceId: string, stepId: string, language: string, content: Blob) {
    if (this.platformService.isCordova()) {
      await this.filesystem.createFileInFolder(content, this.filenameForMedia(stepId, language), this.folderForMedia(experienceId));
      return;
    }

    const key = this.keyForMedia(experienceId, stepId, language);
    await this.storage.set(key, this.blobToBase64(content));
  }

  async saveExperienceImage(experienceId: string, content: Blob) {
    if (this.platformService.isCordova()) {
      await this.filesystem.createFileInFolder(content, this.filenameForExperienceImage(experienceId), this.folderForExperienceImage(experienceId));
      return;
    }

    const key = this.keyForExperienceImage(experienceId);
    await this.storage.set(key, this.blobToBase64(content));
  }

  async saveStepImage(experienceId: string, stepId: string, content: Blob) {
    if (this.platformService.isCordova()) {
      await this.filesystem.createFileInFolder(content, this.filenameForStepImage(stepId), this.folderForStepImage(experienceId));
      return;
    }

    const key = this.keyForStepImage(experienceId, stepId);
    await this.storage.set(key, this.blobToBase64(content));
  }

  async getStepImage(experienceId: string, stepId: string): Promise<Nullable<string>> {
    if (this.platformService.isCordova()) {
      return this.filesystem.readFileAsBase64(this.folderForStepImage(experienceId), this.filenameForStepImage(stepId));
      // return this.filesystem.getFilepath(this.folderForStepImage(experienceId), this.filenameForStepImage(stepId));
    }

    const key = this.keyForStepImage(experienceId, stepId);
    return await this.storage.get(key);
  }

  async getExperienceImage(experienceId: string): Promise<Nullable<string>> {
    if (this.platformService.isCordova()) {
      return this.filesystem.readFileAsBase64(this.folderForExperienceImage(experienceId), this.filenameForExperienceImage(experienceId));
      // return this.filesystem.getFilepath(this.folderForExperienceImage(experienceId), this.filenameForExperienceImage(experienceId));
    }

    const key = this.keyForExperienceImage(experienceId);
    return await this.storage.get(key);
  }

  async getImagePath(experienceId: string, stepId: string): Promise<Nullable<string>> {
    if (this.platformService.isCordova()) {
      return this.filesystem.getFilepath(this.folderForStepImage(experienceId), this.filenameForStepImage(stepId));
    }

    const key = this.keyForStepImage(experienceId, stepId);
    return await this.storage.get(key);
  }

  async get(experienceId: string, stepId: string, language: string): Promise<Nullable<string>> {
    if (this.platformService.isCordova()) {
      return this.filesystem.getFilepath(this.folderForMedia(experienceId), this.filenameForMedia(stepId, language));
    }

    const key = this.keyForMedia(experienceId, stepId, language);
    return await this.storage.get(key);
  }

  async isStored(experienceId: string, stepId: string, language: string): Promise<boolean> {
    if (this.platformService.isCordova()) {
      return this.filesystem.fileExists(this.folderForMedia(experienceId), this.filenameForMedia(stepId, language));
    }

    const key = this.keyForMedia(experienceId, stepId, language);
    const media = await this.storage.get(key);
    return media !== null;
  }

  async isImageStored(experienceId: string, stepId: string): Promise<boolean> {
    if (this.platformService.isCordova()) {
      return this.filesystem.fileExists(this.folderForStepImage(experienceId), this.filenameForStepImage(stepId));
    }

    const key = this.keyForStepImage(experienceId, stepId);
    const media = await this.storage.get(key);
    return media !== null;
  }

  async deleteExperience(experienceId: string) {
    await this.deleteMedias(experienceId);
    await this.deleteExperienceImages(experienceId);
    await this.deleteStepImages(experienceId);
  }

  private async deleteMedias(experienceId: string) {
    const prefix = this.folderForMedia(experienceId);
    if (this.platformService.isCordova()) {
      await this.filesystem.deleteDirectories(prefix);
      return;
    }

    const keys = await this.storage.keys();
    for (const key of keys) {
      if (key.startsWith(prefix)) {
        await this.storage.remove(key);
      }
    }
  }

  private async deleteStepImages(experienceId: string) {
    const prefix = this.folderForStepImage(experienceId);
    if (this.platformService.isCordova()) {
      await this.filesystem.deleteDirectories(prefix);
      return;
    }

    const keys = await this.storage.keys();
    for (const key of keys) {
      if (key.startsWith(prefix)) {
        await this.storage.remove(key);
      }
    }
  }

  private async deleteExperienceImages(experienceId: string) {
    const prefix = this.folderForExperienceImage(experienceId);
    if (this.platformService.isCordova()) {
      await this.filesystem.deleteDirectories(prefix);
      return;
    }

    const keys = await this.storage.keys();
    console.log(prefix)
    for (const key of keys) {
      console.log(key)
      if (key.startsWith(prefix)) {
        console.log('delete!')
        await this.storage.remove(key);
      }
    }
  }

  private async blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result as string);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

  private keyForMedia(experienceId: string, stepId: string, language: string) {
    return `${this.KEY_PREFIX_MEDIA}__${experienceId}__${stepId}__${language}`;
  }

  private keyForStepImage(experienceId: string, stepId: string) {
    return `${this.KEY_PREFIX_STEP_IMAGE}__${experienceId}__${stepId}`;
  }

  private keyForExperienceImage(experienceId: string) {
    return `${this.KEY_PREFIX_EXPERIENCE_IMAGE}__${experienceId}`;
  }

  private folderForMedia(experienceId: string): string {
    return `${this.KEY_PREFIX_MEDIA}__${experienceId}`;
  }

  private folderForStepImage(experienceId: string): string {
    return `${this.KEY_PREFIX_STEP_IMAGE}__${experienceId}`;
  }

  private folderForExperienceImage(experienceId: string): string {
    return `${this.KEY_PREFIX_EXPERIENCE_IMAGE}__${experienceId}`;
  }

  private filenameForMedia(stepId: string, language: string): string {
    return `${stepId}__${language}.mp3`;
  }

  private filenameForStepImage(stepId: string): string {
    return `${stepId}.jpg`;
  }

  private filenameForExperienceImage(experienceId: string): string {
    return `${experienceId}.jpg`;
  }

  private async clearAllMedias() {
    if (this.platformService.isCordova()) {
      await this.filesystem.deleteDirectories(this.KEY_PREFIX_MEDIA);
      return;
    }

    const keys = await this.storage.keys();
    for (const key of keys) {
      if (key.startsWith(`${this.KEY_PREFIX_MEDIA}__`)) {
        await this.storage.remove(key);
      }
    }
  }

  private async clearAllExperienceImages() {
    if (this.platformService.isCordova()) {
      await this.filesystem.deleteDirectories(this.KEY_PREFIX_EXPERIENCE_IMAGE);
      return;
    }

    const keys = await this.storage.keys();
    for (const key of keys) {
      if (key.startsWith(`${this.KEY_PREFIX_EXPERIENCE_IMAGE}__`)) {
        await this.storage.remove(key);
      }
    }
  }

  private async clearAllStepImages() {
    if (this.platformService.isCordova()) {
      await this.filesystem.deleteDirectories(this.KEY_PREFIX_STEP_IMAGE);
      return;
    }

    const keys = await this.storage.keys();
    for (const key of keys) {
      if (key.startsWith(`${this.KEY_PREFIX_STEP_IMAGE}__`)) {
        await this.storage.remove(key);
      }
    }
  }
}
