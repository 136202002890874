import { Nullable } from "src/core/shared/types/nullable.type";

export type TicketRelationPrimitives = {
  ticketId: string;
  email: Nullable<string>;
}

export class TicketRelation {
  constructor(
    public readonly ticketId: string,
    public readonly email: Nullable<string>,
  ) {}

  static fromPrimitives(primitives: TicketRelationPrimitives) {
    return new TicketRelation(primitives.ticketId, primitives.email);
  }


  toPrimitives(): TicketRelationPrimitives {
    return {
      ticketId: this.ticketId,
      email: this.email,
    };
  }
}
