import { TicketRelation } from "src/core/ticket/domain/ticket-relation";
import { Nullable } from "src/core/shared/types/nullable.type";

export class TicketList {
  constructor(
    public readonly reservationId: string,
    public readonly ticketRelations: Array<TicketRelation> = [],
  ) {}

  toPrimitives() {
    return {
      reservationId: this.reservationId,
      ticketList: this.ticketRelations.map(ticketRelation => ticketRelation.toPrimitives()),
    };
  }

  removeEmail(currentUserEmail: Nullable<string>): TicketList {
    if (currentUserEmail === null) {
      return this;
    }

    return new TicketList(
      this.reservationId,
      this.ticketRelations.filter(tr => tr.email !== currentUserEmail),
    );
  }
}
