export type LoggedUserPrimitives = {
  fullName: string;
  email: string;
};

export class LoggedUser {
  constructor(public _fullName: string, public readonly email: string) {}

  get fullName(): string {
    return this._fullName;
  }

  set fullName(value: string) {
    this._fullName = value;
  }

  static fromPrimitives(primitives: LoggedUserPrimitives) {
    return new this(primitives.fullName, primitives.email);
  }

  toPrimitives(): LoggedUserPrimitives {
    return {
      fullName: this._fullName,
      email: this.email,
    };
  }
}
