import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Storable } from 'src/core/shared/storage/domain/storable';
import { StorageService } from "src/core/shared/storage/domain/storage.service";
import { Initializable } from "src/core/shared/initializer/domain/initializable";
import { TranslatorService } from "src/core/shared/translations/domain/translator.service";
import { Nullable } from "src/core/shared/types/nullable.type";
import { LoggedUser } from "src/core/user/domain/logged-user";
import { EventsService } from "src/core/events/events.service";
import { UserLoggedInEvent } from "src/core/events/user-logged-in.event";
import { UserInfo } from "src/core/user/domain/user-info";
import { JWT } from "src/core/authentication/domain/jwt";

@Injectable({
  providedIn: 'root',
})
export class SessionService implements Storable, Initializable {
  private static readonly KEY_IS_LOGGED_IN = 'session.is_logged_in';
  private static readonly KEY_API_TOKEN = 'session.api_token';
  private static readonly KEY_REFRESH_TOKEN = 'session.refresh_token';
  private static readonly KEY_WIZARD_DONE = 'session.wizard_done';
  private static readonly KEY_LANGUAGE = 'session.language';
  private static readonly KEY_LOGGED_USER = 'session.user';
  private static readonly KEY_USER_INFO = 'session.user_info';
  private static readonly KEY_EXPERIENCE_LANGUAGE = 'session.experience_language';

  private isLoggedIn = false;
  private wizardDone = false;
  private readonly defaultLanguage: string;
  private language: string;
  private experienceLanguage: Nullable<string> = null;
  private loggedUser: Nullable<LoggedUser> = null;
  private apiToken: Nullable<JWT> = null;
  private refreshToken: Nullable<string> = null;
  private readySubject = new BehaviorSubject<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  ready$: Observable<boolean> = this.readySubject.asObservable();
  private userInfo: Nullable<UserInfo> = null;

  constructor(
    private readonly storage: StorageService,
    private readonly events: EventsService,
    private readonly translatorService: TranslatorService,
  ) {
    this.defaultLanguage = this.translatorService.defaultLanguage();
    this.language = this.defaultLanguage;
  }

  async init(): Promise<void> {
    this.isLoggedIn = (await this.storage.get(SessionService.KEY_IS_LOGGED_IN)) ?? false;
    const apiToken = await this.storage.get(SessionService.KEY_API_TOKEN);
    if (apiToken) {
      this.apiToken = new JWT(apiToken);
    }
    this.refreshToken = await this.storage.get(SessionService.KEY_REFRESH_TOKEN);
    const loggedUserData = await this.storage.get(SessionService.KEY_LOGGED_USER);
    if (loggedUserData) {
      this.loggedUser = LoggedUser.fromPrimitives(loggedUserData);
      this.events.publishLoggedInEvent(new UserLoggedInEvent(this.loggedUser));
      const userInfo = await this.storage.get(SessionService.KEY_USER_INFO);
      this.userInfo = UserInfo.fromPrimitives(userInfo);
    } else {
      // UPDATE: Think about it a bit more. (if we don't have a logged user, force clearing api token)
      // await this.clearApiToken();
      await this.clearUserInfo();
    }
    this.wizardDone = (await this.storage.get(SessionService.KEY_WIZARD_DONE)) ?? false;
    this.language = (await this.storage.get(SessionService.KEY_LANGUAGE)) ?? this.defaultLanguage;
    this.experienceLanguage = (await this.storage.get(SessionService.KEY_EXPERIENCE_LANGUAGE)) ?? null;

    this.translatorService.changeLanguage(this.language);
    this.readySubject.next(true);
  }

  isAuthenticated(): boolean {
    // return this.apiAccessToken !== null && this.authToken !== null && this.userInfo !== null;
    return this.isLoggedIn;
  }

  async isAuthenticatedPromise(): Promise<boolean> {
    return (await this.storage.get(SessionService.KEY_IS_LOGGED_IN)) ?? false;
  }

  async clear(): Promise<void> {
    this.isLoggedIn = false;
    this.loggedUser = null;
    this.wizardDone = false;
    this.language = this.defaultLanguage;
    this.experienceLanguage = null;
    await this.storage.remove(SessionService.KEY_IS_LOGGED_IN);
    await this.storage.remove(SessionService.KEY_LOGGED_USER);
    await this.storage.remove(SessionService.KEY_WIZARD_DONE);
    await this.storage.remove(SessionService.KEY_LANGUAGE);
    await this.storage.remove(SessionService.KEY_EXPERIENCE_LANGUAGE);
    await this.clearApiToken();
    await this.clearRefreshToken();
    await this.clearUserInfo();
  }

  currentLanguage(): string {
    return this.language;
  }

  async updateLanguage(language: string) {
    this.language = language;
    this.translatorService.changeLanguage(language);
    await this.storage.set(SessionService.KEY_LANGUAGE, language);
  }

  currentExperienceLanguage(): Nullable<string> {
    return this.experienceLanguage;
  }

  async updateExperienceLanguage(language: string) {
    this.experienceLanguage = language;
    await this.storage.set(SessionService.KEY_EXPERIENCE_LANGUAGE, language);
  }

  wizardIsDone(): boolean {
    return this.wizardDone;
  }

  async markWizardAsDone() {
    this.wizardDone = true;
    await this.storage.set(SessionService.KEY_WIZARD_DONE, true);
  }

  getLoggedUser(): Nullable<LoggedUser> {
    return this.loggedUser;
  }

  async setLoggedUser(loggedUser: LoggedUser) {
    this.isLoggedIn = true;
    this.loggedUser = loggedUser;
    await this.storage.set(SessionService.KEY_IS_LOGGED_IN, true);
    await this.storage.set(SessionService.KEY_LOGGED_USER, loggedUser.toPrimitives());
  }

  getApiToken(): Nullable<JWT> {
    return this.apiToken;
  }

  async setApiToken(apiToken: JWT) {
    this.apiToken = apiToken;
    await this.storage.set(SessionService.KEY_API_TOKEN, apiToken.token);
  }

  getRefreshToken(): Nullable<string> {
    return this.refreshToken;
  }

  async setRefreshToken(refreshToken: string) {
    this.refreshToken = refreshToken;
    await this.storage.set(SessionService.KEY_REFRESH_TOKEN, refreshToken);
  }

  async clearRefreshToken() {
    this.refreshToken = null;
    await this.storage.remove(SessionService.KEY_REFRESH_TOKEN);
  }

  getUserInfo(): Nullable<UserInfo> {
    return this.userInfo;
  }

  async updateUserInfo(userInfo: UserInfo) {
    this.userInfo = userInfo;
    await this.storage.set(SessionService.KEY_USER_INFO, userInfo.toPrimitives());
  }

  getCurrentPoints(): number {
    if (this.userInfo === null) {
      return 0;
    }

    return this.userInfo.currentPoints;
  }

  async updateUserFullName(firstName: string, lastName: string) {
    const userInfo = this.getUserInfo();
    if (!userInfo) {
      return;
    }

    userInfo.firstName = firstName;
    userInfo.lastName = lastName;
    await this.updateUserInfo(userInfo);
    const loggedUser = this.getLoggedUser();
    if (!loggedUser) {
      return;
    }

    loggedUser.fullName = userInfo.fullName;
    await this.setLoggedUser(loggedUser);
  }

  private async clearApiToken() {
    this.apiToken = null;
    await this.storage.remove(SessionService.KEY_API_TOKEN);
  }

  private async clearUserInfo() {
    this.userInfo = null;
    await this.storage.remove(SessionService.KEY_USER_INFO);
  }
}
