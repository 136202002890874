import { HttpErrorResponse } from '@angular/common/http';
import { Nullable } from "src/core/shared/types/nullable.type";

export interface ApiExceptionConstructor {
  fromError(error: HttpErrorResponse | Error): ApiException;
}

export abstract class ApiException extends Error implements ApiExceptionConstructor {
  public originalError: Nullable<HttpErrorResponse | Error> = null;
  public code: Nullable<string> = null;

  protected constructor(message: string = '', error: HttpErrorResponse | Error) {
    const trueProto = new.target.prototype; // Almacenar el prototipo verdadero
    super(message);
    Object.setPrototypeOf(this, Object.create(trueProto)); // Crear un nuevo objeto con el prototipo verdadero
    this.name = 'ApiException';
    this.setOriginalError(error);
    this.calculateErrorCode();
  }

  fromError(error: HttpErrorResponse | Error): ApiException {
    throw new Error('El método fromError debe ser implementado en cada subclase de ApiException');
  }

  protected setOriginalError(error: HttpErrorResponse | Error): void {
    this.originalError = error;
  }

  private calculateErrorCode() {
    let code = this.extractApiErrorCode();
    if (code === null) {
      code = this.generateErrorCodeWithPrefix('A');
    }
    this.code = code;
  }

  private extractApiErrorCode(): string | null {
    const isHttpErrorResponse = this.originalError instanceof HttpErrorResponse;
    if (!isHttpErrorResponse) {
      return null;
    }

    const originalError = this.originalError as HttpErrorResponse;
    if (!originalError.error || !originalError.error.hasOwnProperty('error_code')) {
      return null;
    }

    const errorCode = originalError.error.error_code;
    if (typeof errorCode !== 'string' || errorCode.trim() === '') {
      return null;
    }

    return errorCode.trim().toUpperCase();
  }

  private generateErrorCodeWithPrefix(prefix: string): string {
    const errorCode = Math.floor(Math.random() * 10000)
      .toString()
      .padStart(4, '0');
    return `${prefix}-${errorCode}`;
  }
}
