import { StorageService } from "src/core/shared/storage/domain/storage.service";
import { Nullable } from "src/core/shared/types/nullable.type";
import * as semver from 'semver';
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root',
})
export class SystemService {
  static readonly APP_VERSION: string = '1.9.3';
  static readonly KEY_CURRENT_VERSION = 'system.current_version';

  // WARNING: Change this only when it is really needed
  static readonly MINIMUM_APP_VERSION_THAT_FORCES_RESET_STORAGE: string = '1.6.1';

  public static AVAILABLE_LANGUAGES: { [key: string]: { code: string; name: string; nativeName: string; } } = {
    "af": { code: "af", name: "Afrikaans", nativeName: "Afrikaans" },
    "sq": { code: "sq", name: "Albanian", nativeName: "Shqip" },
    "am": { code: "am", name: "Amharic", nativeName: "አማርኛ" },
    "ar": { code: "ar", name: "Arabic", nativeName: "العربية" },
    "hy": { code: "hy", name: "Armenian", nativeName: "Հայերեն" },
    "az": { code: "az", name: "Azerbaijani", nativeName: "Azərbaycan dili" },
    "eu": { code: "eu", name: "Basque", nativeName: "Euskara" },
    "be": { code: "be", name: "Belarusian", nativeName: "Беларуская" },
    "bn": { code: "bn", name: "Bengali", nativeName: "বাংলা" },
    "bs": { code: "bs", name: "Bosnian", nativeName: "Bosanski jezik" },
    "bg": { code: "bg", name: "Bulgarian", nativeName: "Български" },
    "ca": { code: "ca", name: "Catalan", nativeName: "Català" },
    "ceb": { code: "ceb", name: "Cebuano", nativeName: "Cebuano" },
    "ny": { code: "ny", name: "Chichewa", nativeName: "ChiCheŵa" },
    "zh": { code: "zh", name: "Chinese", nativeName: "中文" },
    "ch": { code: "zh", name: "Chinese", nativeName: "中文" }, // To remove when API returns the correct chinese code
    "co": { code: "co", name: "Corsican", nativeName: "Corsu" },
    "hr": { code: "hr", name: "Croatian", nativeName: "Hrvatski" },
    "cs": { code: "cs", name: "Czech", nativeName: "Čeština" },
    "da": { code: "da", name: "Danish", nativeName: "Dansk" },
    "nl": { code: "nl", name: "Dutch", nativeName: "Nederlands" },
    "en": { code: "en", name: "English", nativeName: "English" },
    "eo": { code: "eo", name: "Esperanto", nativeName: "Esperanto" },
    "et": { code: "et", name: "Estonian", nativeName: "Eesti" },
    "tl": { code: "tl", name: "Filipino", nativeName: "Filipino" },
    "fi": { code: "fi", name: "Finnish", nativeName: "Suomi" },
    "fr": { code: "fr", name: "French", nativeName: "Français" },
    "fy": { code: "fy", name: "Frisian", nativeName: "Frysk" },
    "gl": { code: "gl", name: "Galician", nativeName: "Galego" },
    "ka": { code: "ka", name: "Georgian", nativeName: "ქართული" },
    "de": { code: "de", name: "German", nativeName: "Deutsch" },
    "el": { code: "el", name: "Greek", nativeName: "Ελληνικά" },
    "gu": { code: "gu", name: "Gujarati", nativeName: "ગુજરાતી" },
    "ht": { code: "ht", name: "Haitian Creole", nativeName: "Kreyòl ayisyen" },
    "ha": { code: "ha", name: "Hausa", nativeName: "Hausa" },
    "haw": { code: "haw", name: "Hawaiian", nativeName: "ʻŌlelo Hawaiʻi" },
    "he": { code: "he", name: "Hebrew", nativeName: "עברית" },
    "hi": { code: "hi", name: "Hindi", nativeName: "हिन्दी" },
    "hmn": { code: "hmn", name: "Hmong", nativeName: "Hmoob" },
    "hu": { code: "hu", name: "Hungarian", nativeName: "Magyar" },
    "is": { code: "is", name: "Icelandic", nativeName: "Íslenska" },
    "ig": { code: "ig", name: "Igbo", nativeName: "Asụsụ Igbo" },
    "id": { code: "id", name: "Indonesian", nativeName: "Bahasa Indonesia" },
    "ga": { code: "ga", name: "Irish", nativeName: "Gaeilge" },
    "it": { code: "it", name: "Italian", nativeName: "Italiano" },
    "ja": { code: "ja", name: "Japanese", nativeName: "日本語" },
    "jw": { code: "jw", name: "Javanese", nativeName: "Basa Jawa" },
    "kn": { code: "kn", name: "Kannada", nativeName: "ಕನ್ನಡ" },
    "kk": { code: "kk", name: "Kazakh", nativeName: "Қазақ тілі" },
    "km": { code: "km", name: "Khmer", nativeName: "ខ្មែរ" },
    "rw": { code: "rw", name: "Kinyarwanda", nativeName: "Ikinyarwanda" },
    "ko": { code: "ko", name: "Korean", nativeName: "한국어" },
    "ku": { code: "ku", name: "Kurdish", nativeName: "Kurdî" },
    "ky": { code: "ky", name: "Kyrgyz", nativeName: "Кыргызча" },
    "lo": { code: "lo", name: "Lao", nativeName: "ພາສາລາວ" },
    "la": { code: "la", name: "Latin", nativeName: "Latine" },
    "lv": { code: "lv", name: "Latvian", nativeName: "Latviešu valoda" },
    "lt": { code: "lt", name: "Lithuanian", nativeName: "Lietuvių kalba" },
    "lb": { code: "lb", name: "Luxembourgish", nativeName: "Lëtzebuergesch" },
    "mk": { code: "mk", name: "Macedonian", nativeName: "Македонски јазик" },
    "mg": { code: "mg", name: "Malagasy", nativeName: "Malagasy fiteny" },
    "ms": { code: "ms", name: "Malay", nativeName: "Bahasa Melayu" },
    "ml": { code: "ml", name: "Malayalam", nativeName: "മലയാളം" },
    "mt": { code: "mt", name: "Maltese", nativeName: "Malti" },
    "mi": { code: "mi", name: "Maori", nativeName: "Te Reo Māori" },
    "mr": { code: "mr", name: "Marathi", nativeName: "मराठी" },
    "mn": { code: "mn", name: "Mongolian", nativeName: "Монгол" },
    "my": { code: "my", name: "Myanmar (Burmese)", nativeName: "ဗမာစာ" },
    "ne": { code: "ne", name: "Nepali", nativeName: "नेपाली" },
    "no": { code: "no", name: "Norwegian", nativeName: "Norsk" },
    "or": { code: "or", name: "Odia (Oriya)", nativeName: "ଓଡ଼ିଆ" },
    "ps": { code: "ps", name: "Pashto", nativeName: "پښتو" },
    "fa": { code: "fa", name: "Persian", nativeName: "فارسی" },
    "pl": { code: "pl", name: "Polish", nativeName: "Polski" },
    "pt": { code: "pt", name: "Portuguese", nativeName: "Português" },
    "pa": { code: "pa", name: "Punjabi", nativeName: "ਪੰਜਾਬੀ" },
    "ro": { code: "ro", name: "Romanian", nativeName: "Română" },
    "ru": { code: "ru", name: "Russian", nativeName: "Русский" },
    "sm": { code: "sm", name: "Samoan", nativeName: "Gagana fa'a Samoa" },
    "gd": { code: "gd", name: "Scots Gaelic", nativeName: "Gàidhlig" },
    "sr": { code: "sr", name: "Serbian", nativeName: "српски језик" },
    "st": { code: "st", name: "Sesotho", nativeName: "Sesotho" },
    "sn": { code: "sn", name: "Shona", nativeName: "ChiShona" },
    "sd": { code: "sd", name: "Sindhi", nativeName: "سنڌي" },
    "si": { code: "si", name: "Sinhala", nativeName: "සිංහල" },
    "sk": { code: "sk", name: "Slovak", nativeName: "Slovenčina" },
    "sl": { code: "sl", name: "Slovenian", nativeName: "Slovenščina" },
    "so": { code: "so", name: "Somali", nativeName: "Soomaaliga" },
    "es": { code: "es", name: "Spanish", nativeName: "Español" },
    "su": { code: "su", name: "Sundanese", nativeName: "Basa Sunda" },
    "sw": { code: "sw", name: "Swahili", nativeName: "Kiswahili" },
    "sv": { code: "sv", name: "Swedish", nativeName: "Svenska" },
    "tg": { code: "tg", name: "Tajik", nativeName: "тоҷикӣ" },
    "ta": { code: "ta", name: "Tamil", nativeName: "தமிழ்" },
    "tt": { code: "tt", name: "Tatar", nativeName: "татарча" },
    "te": { code: "te", name: "Telugu", nativeName: "తెలుగు" },
    "th": { code: "th", name: "Thai", nativeName: "ไทย" },
    "tr": { code: "tr", name: "Turkish", nativeName: "Türkçe" },
    "tk": { code: "tk", name: "Turkmen", nativeName: "Türkmen" },
    "uk": { code: "uk", name: "Ukrainian", nativeName: "Українська" },
    "ur": { code: "ur", name: "Urdu", nativeName: "اردو" },
    "ug": { code: "ug", name: "Uyghur", nativeName: "Uyƣurqə" },
    "uz": { code: "uz", name: "Uzbek", nativeName: "Oʻzbek" },
    "vi": { code: "vi", name: "Vietnamese", nativeName: "Tiếng Việt" },
    "cy": { code: "cy", name: "Welsh", nativeName: "Cymraeg" },
    "xh": { code: "xh", name: "Xhosa", nativeName: "isiXhosa" },
    "yi": { code: "yi", name: "Yiddish", nativeName: "ייִדיש" },
    "yo": { code: "yo", name: "Yoruba", nativeName: "Yorùbá" },
    "zu": { code: "zu", name: "Zulu", nativeName: "isiZulu" },
  };

  constructor(private readonly storage: StorageService) {}

  static nameForCode(code: string): string {
    const language = SystemService.AVAILABLE_LANGUAGES[code] ?? null;
    if (!language) {
      return '--';
    }

    return language.nativeName;
  }

  async getCurrentStoredAppVersion(): Promise<Nullable<string>> {
    return (await this.storage.get(SystemService.KEY_CURRENT_VERSION)) ?? null;
  }

  async resetStorage() {
    const needsToBeReset = await this.storageNeedsToBeReset();
    if (!needsToBeReset) {
      return;
    }

    await this.storage.reset();
    await this.storeCurrentVersionToStorage();
  }

  async storeCurrentVersionToStorage() {
    await this.storage.set(SystemService.KEY_CURRENT_VERSION, SystemService.APP_VERSION);
  }

  async storageNeedsToBeReset(): Promise<boolean> {
    const currentStoredAppVersion = await this.getCurrentStoredAppVersion();

    // It is a new install
    if (!currentStoredAppVersion) {
      return false;
    }

    return semver.gt(SystemService.MINIMUM_APP_VERSION_THAT_FORCES_RESET_STORAGE, currentStoredAppVersion);
  }
}
