import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Initializable } from "src/core/shared/initializer/domain/initializable";
import { Storable } from "src/core/shared/storage/domain/storable";
import { StorageService } from "src/core/shared/storage/domain/storage.service";
import { Nullable } from "src/core/shared/types/nullable.type";

export type Api = {
  smartlookApiKey: Nullable<string>;
  id: string;
  isProduction: boolean;
  name: string;
  description: string;
  urlMobile: string;
  urlVisitor: string;
  apiKeyMobile: string;
  apiKeyVisitor: string;
  clientId: string;
  clientSecret: string;
  authorizeUrl: string;
  tokenUrl: string;
  p: string;
  redirectURI: string;
  scopes: string[];
};

@Injectable({
  providedIn: 'root',
})
export class ApiService implements Initializable, Storable {
  public static readonly KEY_CURRENT_API = 'api.current';

  private readySubject = new BehaviorSubject<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  ready$: Observable<boolean> = this.readySubject.asObservable();

  // NOTE: first one is the default one
  private current!: Api;
  private apis: Array<Api> = [
    {
      id: 'prod',
      smartlookApiKey: '69caf24d97e5fa313e4fbb947bc4780573521f53', // Project from Casa Batlló
      isProduction: true,
      name: 'API Producción (por defecto)',
      description: 'RS no hace cambios aquí',
      urlMobile: 'https://api.mobile.casabatllo.io/api',
      urlVisitor: 'https://api.visitor.casabatllo.io/api',
      apiKeyMobile: 'cb001aea-af6e-4ff1-a335-45bcb797f824',
      apiKeyVisitor: '2b231829-e704-4854-8e69-4691721bae32',
      clientId: '881496f2-4884-4ead-b379-f1380d82e169',
      clientSecret: '4my8Q~MqhUOyJo.cHP50EbvOUrw~lGvLG6h42c4W',
      authorizeUrl: 'https://gaudib2c.b2clogin.com/gaudib2c.onmicrosoft.com/oauth2/v2.0/authorize',
      tokenUrl: 'https://gaudib2c.b2clogin.com/gaudib2c.onmicrosoft.com/oauth2/v2.0/token',
      p: 'B2C_1A_A_CUSTOM_SIGNIN',
      redirectURI: 'http://localhost:8100',
      scopes: [
        'https://gaudib2c.onmicrosoft.com/public/Visitor.Access',
        'offline_access',
      ],
    },
    {
      id: 'pre',
      isProduction: false,
      smartlookApiKey: 'b5335859c902667839372734b543ec66b84c9ae9', // Project from user <gpa.rs.mail@gmail.com>
      name: 'API Pre-producción',
      description: 'Pruebas RS (y CB)',
      urlMobile: 'https://pre.api.mobile.casabatllo.io/api',
      urlVisitor: 'https://pre.api.visitor.casabatllo.io/api',
      apiKeyMobile: 'cb001aea-af6e-4ff1-a335-45bcb797f824',
      apiKeyVisitor: '2b231829-e704-4854-8e69-4691721bae32',
      clientId: '9ebb5af2-f4aa-4ec4-87f9-e2e384ac23e8',
      clientSecret: '.m98Q~8iKH.HZ-AEgTGiacg-UC_zMb6yJWS_gbS9',
      authorizeUrl: 'https://pregaudib2c.b2clogin.com/pregaudib2c.onmicrosoft.com/oauth2/v2.0/authorize',
      tokenUrl: 'https://pregaudib2c.b2clogin.com/pregaudib2c.onmicrosoft.com/oauth2/v2.0/token',
      p: 'B2C_1A_A_CUSTOM_SIGNIN',
      redirectURI: 'http://localhost:8100',
      scopes: [
        // 'https://gaudib2c.onmicrosoft.com/public/Visitor.Access',
        'https://pregaudib2c.onmicrosoft.com/f4d4f53a-6161-4e31-80b9-3f81c4a2bb0e/Visitor.Access',
        'offline_access',
      ],
    },
    {
      id: 'dev',
      smartlookApiKey: null,
      isProduction: false,
      name: 'API Desarrollo',
      description: 'Pruebas CB',
      urlMobile: 'https://dev.api.mobile.casabatllo.io/api',
      urlVisitor: 'https://dev.api.visitor.casabatllo.io/api',
      apiKeyMobile: 'cb001aea-af6e-4ff1-a335-45bcb797f824',
      apiKeyVisitor: '2b231829-e704-4854-8e69-4691721bae32',
      clientId: '16a462ad-e6cf-45d1-9037-c2de16e03924',
      clientSecret: 'iqa8Q~tcVx0v3aTE7PdaWyjyGyEr9fPnKMM.mak4',
      authorizeUrl: 'https://devgaudib2c.b2clogin.com/devgaudib2c.onmicrosoft.com/oauth2/v2.0/authorize',
      tokenUrl: 'https://devgaudib2c.b2clogin.com/devgaudib2c.onmicrosoft.com/oauth2/v2.0/token',
      p: 'B2C_1A_A_CUSTOM_SIGNIN',
      redirectURI: 'http://localhost:8100',
      scopes: [
        'https://devgaudib2c.onmicrosoft.com/f25b9c92-1ede-41fb-bc28-a055c70fd833/Visitor.Access',
        'offline_access',
      ],
    },
  ];


  constructor(private storage: StorageService) {}

  async init(): Promise<void> {
    await this.initFromStorage();

    this.readySubject.next(true);
  }

  async clear(): Promise<void> {
    // we don't remove anything because it has to have a target API
  }

  getAvailableApis(): Array<Api> {
    return this.apis;
  }

  currentApi(): Api {
    return this.current;
  }

  baseUrl(): string {
    return this.currentApi().urlMobile;
  }

  loginUrl(redirectURI?: string): string {
    const api = this.currentApi();
    const baseUrl = api.authorizeUrl;
    const params = {
      p: api.p,
      client_id: api.clientId,
      redirect_uri: redirectURI ?? api.redirectURI,
      scope: 'openid',
      response_type: 'code',
      nonce: 'defaultNonce',
      prompt: 'login',
      ui_locales: 'es', // hardcoded
    };
    const queryString = this.toQueryString(params);

    return `${baseUrl}?${queryString}`;
  }

  getAccessTokenUrl(): string {
    const api = this.currentApi();
    const baseUrl = api.tokenUrl;
    const params = {
      p: api.p,
    }
    const queryString = this.toQueryString(params);

    return `${baseUrl}?${queryString}`;
  }

  async selectApi(id: string) {
    this.current = this.findById(id) ?? this.getDefaultApi();
    await this.save();
  }

  isProduction(): boolean {
    if (!this.currentApi()) {
      return true;
    }

    return this.currentApi().isProduction;
  }

  private toQueryString(params: Record<string, any>): string {
    const searchParams = new URLSearchParams();

    for (const key in params) {
      if (params.hasOwnProperty(key) && params[key] !== undefined && params[key] !== null) {
        searchParams.append(key, params[key].toString());
      }
    }

    return searchParams.toString();
  }

  private async initFromStorage(): Promise<void> {
    const apiId = (await this.storage.get(ApiService.KEY_CURRENT_API)) ?? null;
    if (apiId === null) {
      this.current = this.getDefaultApi();
      await this.save();
    } else {
      const api = this.findById(apiId);
      this.current = api ?? this.getDefaultApi();
    }
  }

  private findById(id: string): Nullable<Api> {
    return this.apis.find((api: Api) => api.id === id) ?? null;
  }

  private async save() {
    await this.storage.set(ApiService.KEY_CURRENT_API, this.current.id);
    await this.initFromStorage();
  }

  private getDefaultApi(): Api {
    return this.apis[0]; // prod
    // return this.apis[1]; // pre
    // return this.apis[2]; // dev
  }
}
