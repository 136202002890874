import { Nullable } from "src/core/shared/types/nullable.type";

export type UserInfoPrimitives = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  currentPoints: number;
  completedMissions: Array<CompletedMission>;
  redeemedPrizes: Array<RedeemedPrize>;
  usedPoints: number;
  numAvailablePrizes: number;
  numRedeemedPrizes: number;
  numCompletedMissions: number;
  isFirstLogin?: boolean;
};

export type CompletedMission = {
  id: string;
  points: number;
}

export type RedeemedPrize = {
  id: string;
  points: number;
}

export class UserInfo {
  fullName: string = '';

  constructor(
    public readonly id: string,
    public readonly email: string,
    private _firstName: string,
    private _lastName: string,
    public currentPoints: number,
    public readonly completedMissions: Array<CompletedMission>,
    public readonly redeemedPrizes: Array<RedeemedPrize>,
    public readonly usedPoints: number,
    public readonly numAvailablePrizes: number,
    public readonly numRedeemedPrizes: number,
    public readonly numCompletedMissions: number,
    public readonly isFirstLogin: boolean,
  ) {
    this.buildFullName();
  }

  get firstName(): string {
    return this._firstName;
  }

  set firstName(value: string) {
    this._firstName = value;
    this.buildFullName();
  }

  get lastName(): string {
    return this._lastName;
  }

  set lastName(value: string) {
    this.buildFullName();
    this._lastName = value;
  }

  static fromPrimitives(primitives: UserInfoPrimitives): Nullable<UserInfo> {
    if (!primitives || !primitives.firstName) {
      return null;
    }

    return new this(
      primitives.id,
      primitives.email,
      primitives.firstName,
      primitives.lastName,
      primitives.currentPoints,
      primitives.completedMissions,
      primitives.redeemedPrizes,
      primitives.usedPoints,
      primitives.numAvailablePrizes,
      primitives.numRedeemedPrizes,
      primitives.numCompletedMissions,
      primitives.isFirstLogin ?? false,
    );
  }

  toPrimitives(): UserInfoPrimitives {
    return {
      id: this.id,
      email: this.email,
      firstName: this._firstName,
      lastName: this._lastName,
      currentPoints: this.currentPoints,
      completedMissions: this.completedMissions,
      redeemedPrizes: this.redeemedPrizes,
      usedPoints: this.usedPoints,
      numAvailablePrizes: this.numAvailablePrizes,
      numRedeemedPrizes: this.numRedeemedPrizes,
      numCompletedMissions: this.numCompletedMissions,
    };
  }

  isMissionCompleted(missionId: string): boolean {
    const completedMissionIds = this.completedMissions.map(o => o.id);
    return completedMissionIds.includes(missionId);
  }

  getPointsForMission(missionId: string): number {
    const mission = this.completedMissions.find(o => o.id === missionId);
    if (!mission) {
      return 0;
    }

    return mission.points;
  }

  isPrizeRedeemed(prizeId: string): boolean {
    return this.redeemedPrizes.map(o => o.id).includes(prizeId);
  }

  getPointsForPrize(prizeId: string): number {
    const prize = this.redeemedPrizes.find(o => o.id === prizeId);
    if (!prize) {
      return 0;
    }

    return prize.points;
  }

  addRedeemedPrize(prizeId: string, points: number) {
    if (this.currentPoints < points) {
      return;
    }

    this.redeemedPrizes.push({
      id: prizeId,
      points,
    });

    this.currentPoints -= points;
  }

  addCompletedMission(missionId: string, points: any) {
    this.completedMissions.push({
      id: missionId,
      points,
    });

    this.currentPoints += points;
  }

  private buildFullName() {
    this.fullName = [ this._firstName, this._lastName ].join(' ');
  }
}
