import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import { environment } from 'src/environments/environment';
import { SessionService } from 'src/core/session/domain/session.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ClientException } from "src/core/shared/exceptions/client-exception";
import { ApiException } from "src/core/shared/exceptions/api-exception";
import { Nullable } from "src/core/shared/types/nullable.type";

type SentryTags = {
  rs_error_code: Nullable<string> | undefined;
  rs_user_id?: string;
  rs_user_email?: string;
  rs_user_full_name?: string;
};

@Injectable({
  providedIn: 'any'
})
export class SentryIonicErrorLogger {
  constructor(private readonly session: SessionService) {}

  async log(error: Error | ClientException | ApiException, code?: string) {
    const enabled = false;
    if (!enabled) {
      console.log(
        `External logger service (Sentry) not configured yet. Skipping…`,
      );
      return;
    }

    if (!environment.is_dist) {
      console.log(
        `Environment: ${environment.name}. Not sending error to Sentry because it is not a "dist" environment`,
      );
      return;
    }

    console.log(`Environment: ${environment.name}. Sending error to Sentry`);
    try {
      const message = this.buildMessage(error, code);
      const user = this.session.getLoggedUser();
      const userInfo = this.session.getUserInfo();
      const tags: SentryTags = {
        rs_error_code: this.getErrorCode(error, code),
      };
      if (user !== null && userInfo !== null) {
        const userId = userInfo.id;
        Sentry.setUser({
          id: userId,
          email: user.email,
        });
        tags.rs_user_id = userId;
        tags.rs_user_email = user.email;
        tags.rs_user_full_name = user.fullName;
      }
      let originalError;
      if (error instanceof ApiException) {
        originalError = error.originalError;
      } else {
        originalError = error;
      }
      Sentry.captureException(error, {
        tags,
        extra: {
          message,
          original_error: originalError,
        },
      });
    } catch (e) {
      console.error('error while sending to Sentry:')
      console.error(e);
    }
  }

  private getErrorCode(error: Error | ClientException | ApiException, code: string | undefined) {
    if (error instanceof ApiException) {
      return error.code;
    }

    return code;
  }

  private buildMessage(error: Error | ClientException | ApiException, code: string | undefined): string {
    const message = error.message;
    if (error instanceof ApiException) {
      const status = (error.originalError as HttpErrorResponse)?.status;
      return `API uncontrolled error (status: ${status}, code: ${error.code}, message: ${message})`;
    }

    if (error instanceof ClientException) {
      return `Client controlled error, code ${code}: ${message}`;
    }

    return `Client uncontrolled error, code ${code}: ${message}`;
  }
}
